
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import PaginationWat from '@/components/PaginationWat.vue'
import moment from 'moment'

export default defineComponent({
    components: { IonInfiniteScroll, IonInfiniteScrollContent, PaginationWat },
    setup() {
        const recordStore: any = inject('recordStore')
        const page = ref(1)
        const infinityPage = ref(0)
        const isMobile = ref(false)
        const isDesktop = ref(false)
        const isInfiniteScrollDisabled = ref(false)

        const loadData = async (e: any, mobile: boolean) => {
            if (mobile) {
                infinityPage.value++
                await recordStore.getUserPoints(infinityPage.value, mobile)
                e.target.complete()
                if (
                    infinityPage.value ==
                    recordStore.state.recordState.userPoints.maxPages - 1
                ) {
                    isInfiniteScrollDisabled.value = true
                }
                return
            }
            recordStore.state.recordState.loading = true
            await recordStore.getUserPoints(page.value - 1, mobile)
        }
        onBeforeMount(async () => {
            await recordStore.getUserPoints(0)
            const desktop = window.matchMedia('(min-width: 700px)')
            const mobile = window.matchMedia('(max-width: 700px)')
            if (mobile.matches) {
                isDesktop.value = false
                isMobile.value = true
            } else {
                isMobile.value = false
                isDesktop.value = true
            }
        })
        return {
            points: computed(
                () => recordStore.state.recordState.userPoints.points
            ),
            page,
            isMobile,
            isDesktop,
            totalRegisters: computed(() => {
                const numericCount = Number(recordStore.state.recordState.userPoints.count);
                return numericCount;
            }),
            isInfiniteScrollDisabled,
            loadData,
            moment,
            redeemInvalidated: computed(() => {
                return (type:String) => {
                    return type.toLowerCase().includes('redeem');
                };
            })
        }
    },
})
